p {
    margin: auto;
}

.cover-home {
    height: 80vh;
    background: url(../../pages/coverImages/uptownhope_bkgrd.png) no-repeat center center fixed;
    background-size: 80vw 110vh;
    -webkit-background-size: 120vw 100vh;
    -moz-background-size: 80vw 110vh;
    -o-background-size: 80vw 110vh;
    background-position-y: 105px;
}

.wrapper {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-content {
    text-align: left;
    width: 70vw;
    height: auto;
}

.main {
    background-color: rgba(0, 0, 0, .95);
    position: relative;
    margin: auto;
}

.top-block {
    background: rgba(230, 115, 14, 1);
    color:  #0b2ca3;
    padding-top: 1em;
    padding-bottom: .6em;
}

.top-block h1{
    color: #ffffff;
    text-shadow: 2px 2px #0b2ca3;
}

.wrapper-symbols {
    display: flex;
    justify-content: center;
    background: #ffffff;
}

.symbol-block {
    color:  rgb(230, 115, 14);
    padding-top: 1.4em;
    padding-bottom: 1em;
    padding-right: 4.2em;
    font-weight: 500;
    max-width: 20%;
}

.acronym {
    text-shadow: 1.5px 1.3px #0b2ca3;
}

.icon {
    font-size: 2vw;
}

.symbol-block h3 {
    font-weight: bold;
    padding-top: .4em;
}

.info {
    padding: 1em;
    background-color: #ffffff; 
    text-align: center;
}

.info-image {
    width: 45vw;
    height: 500px;
    box-shadow: 12px 15px 5px #b4b4b4;
}

.info-text{
    font-size: 3vw;
}

.info-text a:link {
    color:rgba(230, 115, 14, 1);
    font-size: 3vw;
    text-decoration: none;
}

.info-bottom {
    background-color: #ffffff;
    padding-bottom: 1em;
}

@media screen and (min-width: 1200px) {
    .info {
        display: flex;
        padding: 1em 3em;
        background-color: #ffffff; 
        text-align: center;
    }
}

@media screen and (max-width: 1200px) {
    .symbol-block {
        padding-right: 1em;
        max-width: 20%;
    }
    
    .icon {
        font-size: 1.8vw;
    }

    .symbol-block h3 {
        font-weight: bold;
        font-size: 2.2vw;
    }

    .symbol-block p {
        margin: auto;
        font-size: 2.2vw;
    }

    .info-image {
        width: 60vw;
        height: 45vh;
        box-shadow: 12px 15px 5px #b4b4b4;
    }

    .info-text{
        padding-top: 1em;
    }
}

@media screen and (max-width: 920px) {
    .main {
        background: url(../../pages/coverImages/uptownhope_bkgrd.png) no-repeat center center;
        background-size: 970px 1000px;
        -webkit-background-size: 970px 1000px;
        -moz-background-size: 970px 1000px;
        -o-background-size: 970px 1000px;
        background-position-y: 60px;
        position: relative;
    }
    
    .symbol-block {
        text-align: center;
    }

    .icon {
        font-size: 2.8vw;
    }

    .symbol-block h3 {
        font-weight: bolder;
        font-size: 3vw;
    }

    .symbol-block p {
        margin: auto;
        font-size: 2.7vw;
    }

    .info-image {
        width: 63vw;
        height: 31vh;
        box-shadow: 12px 15px 5px #b4b4b4;
    }

    .info-text{
        font-size: 3.5vw;
        padding-top: 1.5em;
    }

    .info-bottom{
        font-size: 3.1vw;
    }
}