.staffing {
    background: url(../../pages/coverImages/corporate1.jpg) no-repeat center center fixed;
    background-size: cover;
    height: auto; 
    background-position-y: 40px;
    position: relative;
}

#pg-staffing {
    margin: auto;
    padding: .5em;
}

.cover {
    height: 60vh;
}

.wrapper {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-content-staffing {
    text-align: center; 
    width: 70vw;
    height: auto;
}

#title {
    margin: auto;
    padding: 20px;
    background-color: #e8e8ea;
    color: rgba(230, 115, 14, 1);
}

.staff-content {
    display: flex;
    margin-top: 2em;
    font-size: large;
}

 .staff-1 { 
    text-align: left;
    padding-right: 1em;
    width: 55%;
    padding-left: 2em;
}

.button {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 20px;
}

.staff-2 {
    margin-right: 1em;
    width: 30%;
}

.image-bus {
    width: 29vw;
    height: 37vh;
    box-shadow: 12px 15px 5px #b4b4b4;
}

#contact {
    width: 18vw;
    height: 65px;
}

@media (max-width: 1150px) {
    .staffing {
        background: url(../../pages/coverImages/corporate1.jpg) no-repeat center center fixed;    
        background-size: 950px 1000px;
        -webkit-background-size: 950px 1000px;
        -moz-background-size: 950px 1000px;
        -o-background-size: 950px 1000px;
        background-position-y: -150px;
        position: relative;
    }

    .staff-content {
        display: grid;
    }

    .staff-1 { 
        width: 100%;
        /* font-size: 1.7vw; */
    }

    .staff-2 {
        margin-right: 1em;
        width: 100%;
    }

    .image-bus {
        width: 44vw;
        height: 33vh;
        box-shadow: 12px 15px 5px #b4b4b4;
    }

    #contact-us {
        text-align: center;
    }

    #contact {
        width: 38vw;
        height: 58px;
    }
}

@media (max-width: 900px) {
    .image-bus {
        width: 50vw;
        height: 28vh;
        box-shadow: 12px 15px 5px #b4b4b4;
    }
}