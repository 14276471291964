.employment {
    background: url(../../pages/coverImages/application.jpg) no-repeat center center fixed;
    background-size: cover;
    height: auto; 
    background-position-y: 40px;
    position: relative;
}

.cover {
    height: 60vh;
}

#wrapper {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

#page-content {
    text-align: center;
    width: 70vw;
    height: auto;
    margin-bottom: 2em;
}

#title {
    margin: auto;
    padding: 20px;
    background-color: #e8e8ea;
    color: rgba(230, 115, 14, 1);
}

#banner {
    margin: 1em;
    text-align: center;
}

.career-content {
    display: flex;
}

#top-pg {
    margin: 2.5em;
    padding-left: 3.5em;
    padding-right: 3.5em;
}

.col-1 {
    text-align: left;
    padding-right: 15px;
    width: 55%;
    padding-bottom: 2em;
}

.col-2 {
    text-align: center;
    width: 45%;
    height: 100%;
}

.image-car {
    width: 30vw;
    height: 40vh;
    box-shadow: 12px 15px 5px #b4b4b4;
}

.button {
    padding-bottom: 2em;
}

#contact-cs {
    width: 22vw;
    height: 55px;
}

@media (max-width: 1150px) {
    .employment {
        background: url(../../pages/coverImages/application.jpg) no-repeat center center;
        background-size: 950px 1000px;
        -webkit-background-size: 950px 1000px;
        -moz-background-size: 950px 1000px;
        -o-background-size: 950px 1000px;
        background-position-y: -150px;
        position: relative;
    }

    #banner {
        margin-top: 1em;
        text-align: center;
    }

    .career-content {
        display: grid;
        justify-content: center;
    }

    #top-pg {
        margin: 2.5em;
        padding-left: 0.7em;
        padding-right: 0.7em;
        text-align: center;
    }
    
    .col-1 {
        /* padding-right: 15px; */
        width: fit-content;
        align-items: center;
    }
    
    .col-2 {
        text-align: center;
        width: 100%;
        height: 100%;
    }

    .image-car {
        width: 63vw;
        height: 51vh;
        box-shadow: 12px 15px 5px #b4b4b4;
    }

    #contact-cs {
        width: 42vw;
        height: 55px;
    }

}

@media (max-width: 900px) {
    .image-car {
        width: 60vw;
        height: 40vh;
        box-shadow: 12px 15px 5px #b4b4b4;
    }
}