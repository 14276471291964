@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,300,700);

* {
  font-family: Open Sans;
}

footer {		
  width: 100%;
  height: auto;
  position: relative;
  background: rgba(230, 115, 14, 1);
  display: inline-block;
  text-align: center;
  font-weight: 700;
  margin: auto;
}

.footer-distributed{
	/* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12); */
	box-sizing: border-box;
	width: 100%;
	text-align: left;
	font: bold 16px sans-serif;
	padding: 2em;
	padding-left: 4em;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
	display: inline-block;
	padding-top: 20px;
	vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left{
	padding-left: 2em;
	width: 40%;
}

/* The company logo */

.footer-left .logo{
	margin-left: 4rem;
}

/* Footer links */

.footer-distributed .footer-links{
	color:  #0b2ca3;
	margin: 20px 0 12px;
	padding: 0;
}

.footer-distributed .footer-links a{
	display:inline-block;
	line-height: 1.8;
	font-size: 1.3vw;
  	font-weight:400;
	text-decoration: none;
	color:  inherit;
}

.footer-distributed .footer-company-name{
	color:  #222;
	font-size: 14px;
	font-weight: normal;
	margin-left: 4rem;
}

/* Footer Center */

.footer-distributed .footer-center{
	width: 29%;
}

.footer-distributed .footer-center i{
	background-color:  #33383b;
	color: #ffffff;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	text-align: center;
	margin: 10px 15px;
	vertical-align: middle;
}

.footer-distributed .footer-center p{
	display: inline-block;
	color: #0b2ca3;
	/* font-size: 1.2vw; */
  	font-weight: normal;
	vertical-align: middle;
	margin:0;
}

.footer-distributed .footer-center p span{
	display:block;
	font-weight: normal;
	font-size: 1.3vw;
	line-height:1.8;
}

.footer-distributed .footer-center p a{
	color:  #0b2ca3;
	text-decoration: none;
}

.footer-distributed .footer-links a:before {
	content: "|";
	font-weight:300;
	font-size: 1.2vw;
	left: 0;
	color: #fff;
	display: inline-block;
	padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */

.footer-distributed .footer-right{
	width: 31%;
	padding-left: 1em;
	text-align: center;
}

.footer-distributed .footer-company-about{
	line-height: 20px;
	color:  #0b2ca3;
	font-size: 13px;
	font-weight: normal;
	margin: 0;
}

.footer-distributed .footer-company-about span{
	display: block;
	color:  #0b2ca3;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
}

.footer-distributed .footer-icons{
	padding-left: 3.5em;
}

.social-icons{
	display: inline-block;
	cursor: pointer;
	margin-right: .3rem;
}

.footer-copyright {
	color: #0b2ca3;
	font-size: 12px;
}

.footer-copyright {
	color: #0b2ca3;
	font-size: 12px;
}

@media (max-width: 1400px) {
	.footer-distributed{
		/* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12); */
		box-sizing: border-box;
		width: 100%;
		text-align: left;
		font: bold 16px sans-serif;
		padding: 20px;
		padding-left: 3em;
	}
}


@media (max-width: 1250px) {

	.footer-distributed{
		font: bold 14px sans-serif;
		padding-right: 4.5rem;
	}

	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed .footer-right{
		width: 100%;
		/* margin-bottom: 40px; */
		text-align: center;
		background: #e6730e;
		margin: auto;
	}

	.footer-left .logo{
		margin-left: auto
	}
	
	.footer-distributed .footer-links{
		color:  #0b2ca3;
		margin: 20px 0 12px;
		padding: 0;
	}
	
	.footer-distributed .footer-company-name{
		color:  #222;
		font-size: 14px;
		font-weight: normal;
		margin-left: auto;
	}
	

	.footer-distributed .footer-center i{
		margin-left: 0;
		padding-top: 5em;
		padding-bottom: 10px;
	}

	.footer-distributed .footer-right{
		padding-left: 3em;
	}

	.footer-distributed .footer-icons{
		padding-left: 0;	
	}

	.footer-distributed .footer-links a{
		/* font-size: 1.7vw; */
	}
}

@media(max-width: 650px) {
	.footer-distributed .footer-links a{
		font-size: 16px;
	}

	.footer-center {
		/* font-size: 2.9vw; */
	}
}
