
  .google-map {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    height: 100vh;
  }
  
  .title {
    color: rgba(230, 115, 14, 1);
    padding-top: 1em;
    font-size: 18px;
  }

  .marker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: rgba(6, 9, 167, 0.87);
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  
  @media screen and (min-width: 799px) {
    .google-map {
      height: 60vh;
    }
  
    .pin {
      width: 15vw;
    }
  
    .pin-icon {
      font-size: 10vw;
    }
  }