.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 5;
    padding-left: 1rem;
    padding-right: 2rem;
}

.navbar-brand .nav-logo {
    margin: 0.6rem;
    margin-left: 2rem;
}

.nav-link {
    text-align: center;
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
    font-size: 17px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgba(230, 115, 14, 1) !important;
}

.nav-link:hover {
    background-color: #0b2ca3; /*will change background-color of element on hover */
    color: white !important; 
    border-radius: 50%;
}

.nav-link:active {
    color: #0b2ca3;
}

@media (max-width: 1400px) {
    .nav-link:hover {
        background-color: #0b2ca3; /*will change background-color of element on hover */
        color: white !important; 
        border-radius: 4vw;
    }
}