p {
    margin: auto;
}

.contact {
    margin: auto;
    padding-bottom: 1em;
}

.wrapper {
    background-color: #ffffff;
}

.page-content {
    text-align: left;
    width: 70vw;
}



.uptown-hope {
    padding-top: 1em;
    text-align: center;
}

.uptown-hope p {
    font-size: 22px;
}

.uptown-hope a:link {
    color:rgba(230, 115, 14, 1);
    font-size: 20px;
    text-decoration: none;
}

.form {
    padding-top: 4em;
}

.contact_button {
    padding-top: .5em;
    
}

.directions {
    padding-bottom: 3em;
}

@media (max-width: 1000px) {
    .uptown-hope p {
        font-size: 2.4vw;
    } 

    .uptown-hope a:link {
        font-size: 2.3vw;
    }
    
    .container {
        font-size: 2.5vw;
        padding-top: 1em;
    }

}

@media (min-width: 1000px) {
    .contact_info {
        display: flex;
        justify-content: center;
        padding: 2em;
    }
    
    .uptown-hope {
        width: 70%;
        text-align: left;
    }
}

@media (max-width: 800px) {
    .google-map {
        height: 50vh;
        width: auto;
    }
}