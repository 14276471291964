p {
    margin: auto;
}

.cover {
    height: 60vh;
}

.wrapper {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-content {
    text-align: left;
    width: 70vw;
    height: auto;
}

.service-page {
    background: url(../../pages/coverImages/Servicespage_image.jpg) no-repeat center center fixed;
    width: 100vw;
    height: auto; 
    background-size: cover;
    background-position-y: 10px;
    position: relative;
}

#title {
    margin: auto;
    padding: 20px;
    background-color: #e8e8ea;
    color: rgba(230, 115, 14, 1);
}

.services {
    margin: auto;
}

.services-text {
    margin: auto;
    padding: 20px;
    background-color: #ffffff;
}

@media screen and (max-width: 900px) {
    .service-page {
        background: url(../../pages/coverImages/Servicespage_image.jpg) no-repeat center center;
        background-size: 980px 1000px;
        -webkit-background-size: 980px 1000px;
        -moz-background-size: 980px 1000px;
        -o-background-size: 980px 1000px;
        background-position-y: -50px;
        position: relative;
    }
}